<template>
  <v-card v-if="user">
    <v-card-title primary-title>
      <v-row class="fill-height" align="center" justify="space-around">
        <v-col class="justify-center" cols="12">
          <h3 class="headline my-4 " justify-center>
            {{ $t('Chef.new_chef_title') }}
          </h3>
        </v-col>
        <v-col cols="12">
          <h3 justify-center>{{ $t('Chef.new_chef_sub_title') }}</h3>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="mt-4">
        <v-col cols="12" v-html="$t('Chef.new_chef_line1')"> </v-col>
      </v-row>

      <v-row class="my-3" v-if="!emailVerified">
        <v-col cols="12" v-html="$t('Chef.new_chef_line2', [user.email])">
        </v-col>
      </v-row>

      <v-row class="my-3">
        <v-col cols="12" v-html="$t('Chef.new_chef_line3', ['my/profile'])">
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-html="$t('Chef.new_chef_line4')"> </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="mt-4">
      <v-row class="fill-height" align="center" justify="space-around">
        <v-col class="justify-center" cols="12"
          ><v-btn color="primary" to="/my/profile">Setup Profile</v-btn></v-col
        ></v-row
      >
    </v-card-actions>
    <v-img
      contain
      height="200"
      src="https://storage.googleapis.com/img.fod.live/static/app/chef/thank-you-1428147_1920_xs.png"
    >
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    emailVerified: Boolean,
    user: Object
  },
  mounted() {
    this.$gtm.trackEvent({
      event: 'takein_event', // Event type [default = 'interaction'] (Optional)
      category: 'sign_up',
      action: 'signup_welcome',
      label: 'signup_welcome',
      method: 'signup_welcome',
      payload: { method: 'signup_welcome', source: 'fb2' }
    })
  }
}
</script>

<style></style>
